.calloutRequests{
    padding: 20px;
    margin: 20px 0;
    border: 0px solid lightsteelblue;
    border-left-width: 5px;
    border-radius: 5px;
    color: black;
}

.calloutRequestsSent{
    padding: 20px;
    margin: 20px 0;
    border: 0px solid lightgreen;
    border-left-width: 5px;
    border-radius: 5px;
    color: black;
}

.calloutRequests:hover{
    background-color: lightsteelblue;
    color: black;
}

.inputFileRequest{
    margin-right: 40%;
}

.submitButtonRequest{
    margin-left: 85%;
}

.advice{
    color:darkgreen;
    
    font-weight: bold;
}
