.customClickableLabel{
    cursor: pointer;
    padding: 10px;
    color: black;
    border-radius: 50px;
    background-color: lightsteelblue;
}

.customClickableLabel:hover{
    text-decoration: none;
    color: black;
}

input[type=checkbox] {
    transform: scale(1.5);
    margin-top: 15px;
    margin-right: 15px;
}

input[type=radio] {
    transform: scale(1.5);
    margin-top: 15px;
    margin-right: 15px;
}

.customChoice{
    color: black;
}

.customChoice:hover{
    color: black;
}

input[type=button]{
    background-color: #008CBA !important;
    padding: 50px;
}

.customText{
    font-size: 15px;
    color: red;
    font-weight: normal;
}