

.callout{
    padding: 20px;
    margin: 20px 0;
    border: 0px solid rgb(201, 129, 47);
    border-left-width: 5px;
    border-radius: 5px;
    color: black;
}

.callout-caseOpen{
    padding: 20px;
    margin: 20px 0;
    border: 0px solid rgb(39, 160, 75);
    border-left-width: 5px;
    border-radius: 5px;
    color: black;
}

.callout:hover{
    text-decoration: none;
    background-color: black;
    color: white;
}
.callout:hover{
    background-color: black;
    color: white;
}

.callout-caseOpen:hover{
    text-decoration: none;
    background-color: black;
    color: white;
}

.customLink{
    text-decoration: none;
    color: black;
}

.customLink:hover
{
    text-decoration: none;
    color: white;
}


