.customBackground{
    background: white;
    padding: 15px;
}

.customWidth{
    width: 25%;
}

.customCenter{
    margin-left: 50%;
    margin-top: 15%;
}

.customForm{
    margin-right: 50%;
}

.customAlignRight{
    margin-left: 85%;
}

.customWhiteText:hover{
    text-decoration: none;
    color: white;
}

.customButtonLabel{
    margin-bottom: 0px;
}

.customButtonQualified{
    border: none;
    cursor: pointer;
}