.headerOfGrid{
    border: 1px solid rgb(219, 219, 219);
    padding: 10px;
    font-weight: bold;
}

.none{
    border: none !important;
}

.bodyOfGrid{
    border: 1px solid rgb(219, 219, 219);
    padding: 10px;
    
}

@media (max-width: 1024px) {
    .newsrow {
     display:block;
    }
    .newsbox{
      display:flex;
    }
    .column {
    height:auto;
    }
  
  }

.customButtonPositionSave{
    margin-left: 335px;
    margin-top: 15px;
}

.customButtonPositionEdit{
    margin-left: 385px;
    margin-top: 15px;
}